.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.text-limit-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-limit-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-limit-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.hover\:text-main:hover {
  --tw-text-opacity: 1;
  color: #6f3091;
}

.text-main {
  color: #6f3091;
}
.bg-main {
  background-color: #6f3091;
}
.border-main {
  --tw-border-opacity: 1;
  border-color: #6f3091;
}
.text-danger {
  color: red;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes ldio-nouwmnqbmgs {
  0% {
    background: #fcfcfc;
  }
  12.5% {
    background: #fcfcfc;
  }
  12.625% {
    background: #6f3091;
  }
  100% {
    background: #6f3091;
  }
}
.ldio-nouwmnqbmgs div {
  position: absolute;
  width: 19.18px;
  height: 19.18px;
  background: #6f3091;
  animation: ldio-nouwmnqbmgs 2.4390243902439024s linear infinite;
}
.loadingio-spinner-blocks-cpk7eqc75l {
  width: 137px;
  height: 137px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.ldio-nouwmnqbmgs {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-nouwmnqbmgs div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */

@media only screen and (max-width: 600px) {
  #sidebar {
    width: 100% !important;
  }
}